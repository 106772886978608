export interface IConfig {
  appUrl: string;
  apiUrl: string;
}

// Prod config
export const config: IConfig = {
  appUrl: "https://zapraszalnik.relations365.net",
  apiUrl: "https://relations-social-media-updater.azurewebsites.net",
};

// // Dev config
// export const config: IConfig = {
//     appUrl: "https://zapraszalnik.relations365.net",
//     apiUrl: "https://localhost:8080",
// }
